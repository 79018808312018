body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  /* background-image: url("./assets/screens/BACKGROUND.png"); */
}

a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
section,
samp,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: baseline;
  font: inherit;
}

ul {
  list-style: none;
}
img {
  width: 100%;
  display: block;
}
input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

@font-face {
  font-family: "UnityCondensed-Bold";
  src: url("./assets/fonts/UnityCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "UnityCondensed-Medium";
  src: url("./assets/fonts/UnityCondensed-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "BebasNeue-Regular";
  src: url("./assets/fonts/BebasNeue-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Bebas Neue";
  src: url("../src/assets/fonts/BebasNeueRegular.eot");
  src: url("../src/assets/fonts/BebasNeueRegular.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/BebasNeueRegular.woff2") format("woff2"),
    url("../src/assets/fonts/BebasNeueRegular.woff") format("woff"),
    url("../src/assets/fonts/BebasNeueRegular.ttf") format("truetype"),
    url("../src/assets/fonts/BebasNeueRegular.svg#BebasNeueRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TCCC-UnityCondensed";
  src: url("../src/assets/fonts/TCCC-UnityCondensed-Medium.eot");
  src: url("../src/assets/fonts/TCCC-UnityCondensed-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/TCCC-UnityCondensed-Medium.woff2") format("woff2"),
    url("../src/assets/fonts/TCCC-UnityCondensed-Medium.woff") format("woff"),
    url("../src/assets/fonts/UnityCondensed-Medium.ttf") format("truetype"),
    url("../src/assets/fonts/TCCC-UnityCondensed-Medium.svg#TCCC-UnityCondensed-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bebas Neue Book";
  src: url("../src/assets/fonts/BebasNeueBook.eot");
  src: url("../src/assets/fonts/BebasNeueBook.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/BebasNeueBook.woff2") format("woff2"),
    url("../src/assets/fonts/BebasNeueBook.woff") format("woff"),
    url("../src/assets/fonts/BebasNeueBook.ttf") format("truetype"),
    url("../src/assets/fonts/BebasNeueBook.svg#BebasNeueBook") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TCCC-UnityCondensed";
  src: url("../src/assets/fonts/TCCC-UnityCondensed-Bold.eot");
  src: url("../src/assets/fonts/TCCC-UnityCondensed-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/TCCC-UnityCondensed-Bold.woff2") format("woff2"),
    url("../src/assets/fonts/TCCC-UnityCondensed-Bold.woff") format("woff"),
    url("../src/assets/fonts/TCCC-UnityCondensed-Bold.ttf") format("truetype"),
    url("../src/assets/fonts/TCCC-UnityCondensed-Bold.svg#TCCC-UnityCondensed-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
