@font-face {
  font-family: "Bebas Neue";
  src: url("../../assets/fonts/BebasNeueRegular.eot");
  src: url("../../assets/fonts/BebasNeueRegular.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/BebasNeueRegular.woff2") format("woff2"),
    url("../../assets/fonts/BebasNeueRegular.woff") format("woff"),
    url("../../assets/fonts/BebasNeueRegular.ttf") format("truetype"),
    url("../../assets/fonts/BebasNeueRegular.svg#BebasNeueRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TCCC-UnityCondensed";
  src: url("../../assets/fonts/TCCC-UnityCondensed-Medium.eot");
  src: url("../../assets/fonts/TCCC-UnityCondensed-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.woff2") format("woff2"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.woff") format("woff"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.ttf") format("truetype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.svg#TCCC-UnityCondensed-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bebas Neue Book";
  src: url("../../assets/fonts/BebasNeueBook.eot");
  src: url("../../assets/fonts/BebasNeueBook.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/BebasNeueBook.woff2") format("woff2"),
    url("../../assets/fonts/BebasNeueBook.woff") format("woff"),
    url("../../assets/fonts/BebasNeueBook.ttf") format("truetype"),
    url("../../assets/fonts/BebasNeueBook.svg#BebasNeueBook") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TCCC-UnityCondensed";
  src: url("../../assets/fonts/TCCC-UnityCondensed-Bold.eot");
  src: url("../../assets/fonts/TCCC-UnityCondensed-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.woff2") format("woff2"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.woff") format("woff"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.ttf") format("truetype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.svg#TCCC-UnityCondensed-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
section,
samp,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: baseline;
  font: inherit;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-family: "Open Sans", sans-serif;
  /* Mejorar estilos para evitar ocultar el scroll horizontal */
  overflow-x: hidden;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  width: 100%;
  display: block;
}
a {
  text-decoration: none;
}
body {
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: "Lato", sans-serif;
  font-weight: 500;
}

button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

button:hover {
  /*   background: transparent; */
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

button:active {
  outline: none;
  border: none;
}

button:focus {
  outline: 0;
}

.templateContainer {
  text-transform: uppercase;
  box-sizing: border-box;
}

.container {
  width: calc(100vw * 1204 / 1366);
  margin: 0 auto;
}

.header {
  background-color: #dd0b1c;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
}

@media screen and (max-width: 992px) {
  .header {
    float: none;
    padding: 0;
    position: fixed;
    top: 0;
    z-index: 11;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
footer .logo,
.header .logo {
  cursor: pointer;
}

.header .logo {
  height: 70px;
}

@media screen and (max-width: 992px) {
  .header .logo {
    width: calc(100vw * 98 / 335);
    display: flex;
    align-self: flex-start;
    padding-left: 10px;
    padding-top: 10px;
  }
}

.header .linksBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
}

.header .linksBoxMobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .header .linksBox {
    display: none;
  }
  .header .linksBoxMobile {
    width: 90%;
    display: flex;
    justify-content: center;
    border-top: solid 1px white;
    margin-top: 10px;
    padding: 15px;
  }
}

.header .linksBox .link {
  font-size: calc(100vw * 20 / 1366);
  line-height: calc(100vw * 32 / 1366);
  color: #fff;
  transition: color 300ms ease-in-out;
  font-family: "TCCC-UnityCondensed";
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header .linksBox .link:hover {
  color: #000000;
  position: relative;
  display: block;
  display: flex;
  align-items: center;
}

.header .linksBoxMobile .link {
  color: #fff;
  transition: color 300ms ease-in-out;
  font-family: "TCCC-UnityCondensed";
  cursor: pointer;
  font-size: calc(100vw * 18 / 375);
  display: flex;
  align-items: center;
}
.header .linksBoxMobile .link:nth-of-type(1) {
  margin: 0 0 0 5%;
}
.header .linksBoxMobile .link:nth-of-type(2) {
  margin: 0 5% 0;
}
.header .linksBoxMobile .link:nth-of-type(3) {
  margin: 0 5% 0;
}
.header .linksBoxMobile .link:nth-of-type(4) {
  color: #f5f5f5;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  background-color: #090909;
}

.header .linksBoxMobile .link:nth-of-type(4):hover {
  color: #dd0b1c;
}
.header .linksBox .link:nth-of-type(4) {
  background-color: #070707;
  text-align: center;
  border-radius: 10px;
  color: #faf9f9;
  padding: 5px;
}

.header .linksBox .link:nth-of-type(4):hover {
  color: #dd0b1c;
}




.header .linksBoxMobile .link:nth-of-type(5):hover {
  color: #dd0b1c;
}
.header .linksBoxMobile .link:nth-of-type(5) {
  color: #f5f5f5;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  background-color: #090909;
  margin: 0 5% 0;


}

.header .linksBoxMobile .link:nth-of-type(5):hover {
  color: #dd0b1c;
}
.header .linksBox .link:nth-of-type(5) {
  width: calc(100vw * 70 / 1366);
  background-color: #070707;
  text-align: center;
  justify-content: center;
  border-radius: 10px;
  color: #faf9f9;
  padding: 5px;
}

.header .linksBox .link:nth-of-type(5):hover {
  color: #dd0b1c;
}
footer {
  border-top: 1px solid #dd0b1c;
  margin-top: calc(100vw * 40 / 1366);
  padding: calc(100vw * 40 / 1366) 0;
}

@media screen and (max-width: 992px) {
  footer {
    margin-top: calc(100vw * 40 / 375);
    padding: calc(100vw * 40 / 375) 0;
  }
}
@media screen and (max-width: 992px) {
  .hideFooterDivider {
    border: none;
    margin-top: 0;
  }
}

footer .container {
  clear: both;
  overflow: hidden;
}

footer .container .logo {
  float: left;
  width: calc(100vw * 163 / 1366);
}

@media screen and (max-width: 992px) {
  footer .container .logo {
    float: none;
    width: calc(100vw * 188 / 375);
    margin: 0 auto;
  }
}

footer .container .menu {
  float: left;
  margin-left: calc(100vw * 30 / 1366);
}

@media screen and (max-width: 992px) {
  footer .container .menu {
    float: none;
    margin: 0 auto;
  }
}

footer .container .menu h2 {
  font-family: "TCCC-UnityCondensed";
  font-weight: bold;
  font-size: calc(100vw * 16 / 1366);
  color: #dd0b1c;
  margin-bottom: calc(100vw * 10 / 1366);
  text-transform: none;
}

@media screen and (max-width: 992px) {
  footer .container .menu h2 {
    font-size: calc(100vw * 16 / 375);
    margin-bottom: calc(100vw * 10 / 375);
    margin-top: calc(100vw * 20 / 375);
    text-align: center;
  }
}

ul {
  list-style: none;
}

footer .container .menu ul li {
  margin-bottom: calc(100vw * 5 / 1366);
}

@media screen and (max-width: 992px) {
  footer .container .menu ul li {
    margin-bottom: calc(100vw * 10 / 375);
    text-align: center;
  }
}

footer .container .menu ul li a {
  font-family: "Bebas Neue";
  font-weight: 500;
  color: #000;
  text-decoration: none;
}

footer .container .copy {
  float: right;
  text-transform: uppercase;
  color: #000;
  font-family: "Bebas Neue Book";
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  footer .container .copy {
    float: none;
    text-align: center;
    margin-top: calc(100vw * 30 / 375);
  }
}

/* link {
  font-size: calc(100vw * 20 / 1366);
  line-height: calc(100vw * 32 / 1366);
  color: rgb(83, 81, 81);
  font-family: "Bebas Neue Book";
  cursor: pointer;
  display: flex;
  align-items: center;
}
 */