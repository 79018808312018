@font-face {
  font-family: "Bebas Neue";
  src: url("../../assets/fonts/BebasNeueRegular.eot");
  src: url("../../assets/fonts/BebasNeueRegular.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/BebasNeueRegular.woff2") format("woff2"),
    url("../../assets/fonts/BebasNeueRegular.woff") format("woff"),
    url("../../assets/fonts/BebasNeueRegular.ttf") format("truetype"),
    url("../../assets/fonts/BebasNeueRegular.svg#BebasNeueRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TCCC-UnityCondensed";
  src: url("../../assets/fonts/TCCC-UnityCondensed-Medium.eot");
  src: url("../../assets/fonts/TCCC-UnityCondensed-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.woff2") format("woff2"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.woff") format("woff"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.ttf") format("truetype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.svg#TCCC-UnityCondensed-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bebas Neue Book";
  src: url("../../assets/fonts/BebasNeueBook.eot");
  src: url("../../assets/fonts/BebasNeueBook.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/BebasNeueBook.woff2") format("woff2"),
    url("../../assets/fonts/BebasNeueBook.woff") format("woff"),
    url("../../assets/fonts/BebasNeueBook.ttf") format("truetype"),
    url("../../assets/fonts/BebasNeueBook.svg#BebasNeueBook") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TCCC-UnityCondensed";
  src: url("../../assets/fonts/TCCC-UnityCondensed-Bold.eot");
  src: url("../../assets/fonts/TCCC-UnityCondensed-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.woff2") format("woff2"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.woff") format("woff"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.ttf") format("truetype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.svg#TCCC-UnityCondensed-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

h1 {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: baseline;
  font: inherit;
}

h5 {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: baseline;
  font: inherit;
}


.content {
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.titleSection {
  display: flex;
  background: #fff;
  height: calc(100vw * 201 / 1366);
  align-items: center;
}

@media screen and (max-width: 992px) {
  .titleSection {
    height: calc(100vw * 90 / 375);
    padding-top: calc(100vw * 180 / 375);
  }
}

.titleSection .title {
  font-size: calc(100vw * 41 / 1366);
  line-height: calc(100vw * 49 / 1366);
  color: #0a0a0a;
  font-family: "TCCC-UnityCondensed";
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .titleSection .title {
    font-size: calc(100vw * 20 / 375);
    line-height: calc(100vw * 20 / 375);
  }
}

.h5 {
  font-size: calc(100vw * 28 / 1366);
  line-height: calc(100vw * 29 / 1366);
  color: #000000;
  font-family: "TCCC-UnityCondensed";
  font-weight: bold;
  padding-bottom: calc(40vw * 100 / 1366);
  text-align: justify;
}

@media screen and (max-width: 992px) {
   .h5 {
    font-size: calc(100vw * 16 / 375);
    line-height: calc(100vw * 16 / 375);
  }
}
.p {
  font-size: calc(100vw * 24 / 1366);
  line-height: calc(100vw * 24 / 1366);
  color: #000000;
  font-family: "TCCC-UnityCondensed";
  text-align: justify;
  padding-bottom: calc(10vw * 100 / 1366);
}

@media screen and (max-width: 992px) {
   .p {
    font-size: calc(100vw * 12 / 375);
    line-height: calc(100vw * 12 / 375);
  }
}