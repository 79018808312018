@font-face {
  font-family: "Bebas Neue";
  src: url("../../assets/fonts/BebasNeueRegular.eot");
  src: url("../../assets/fonts/BebasNeueRegular.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/BebasNeueRegular.woff2") format("woff2"),
    url("../../assets/fonts/BebasNeueRegular.woff") format("woff"),
    url("../../assets/fonts/BebasNeueRegular.ttf") format("truetype"),
    url("../../assets/fonts/BebasNeueRegular.svg#BebasNeueRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TCCC-UnityCondensed";
  src: url("../../assets/fonts/TCCC-UnityCondensed-Medium.eot");
  src: url("../../assets/fonts/TCCC-UnityCondensed-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.woff2") format("woff2"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.woff") format("woff"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.ttf") format("truetype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Medium.svg#TCCC-UnityCondensed-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bebas Neue Book";
  src: url("../../assets/fonts/BebasNeueBook.eot");
  src: url("../../assets/fonts/BebasNeueBook.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/BebasNeueBook.woff2") format("woff2"),
    url("../../assets/fonts/BebasNeueBook.woff") format("woff"),
    url("../../assets/fonts/BebasNeueBook.ttf") format("truetype"),
    url("../../assets/fonts/BebasNeueBook.svg#BebasNeueBook") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TCCC-UnityCondensed";
  src: url("../../assets/fonts/TCCC-UnityCondensed-Bold.eot");
  src: url("../../assets/fonts/TCCC-UnityCondensed-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.woff2") format("woff2"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.woff") format("woff"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.ttf") format("truetype"),
    url("../../assets/fonts/TCCC-UnityCondensed-Bold.svg#TCCC-UnityCondensed-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

h1 {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: baseline;
  font: inherit;
}

.content {
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.titleSection {
  display: flex;
  background: #fff;
  height: calc(100vw * 201 / 1366);
  align-items: center;
}

@media screen and (max-width: 992px) {
  .titleSection {
    height: calc(100vw * 151 / 375);
    padding-top: calc(100vw * 110 / 375);
  }
}

.titleSection .title {
  font-size: calc(100vw * 51 / 1366);
  line-height: calc(100vw * 59 / 1366);
  color: #dd0b1c;
  font-family: "TCCC-UnityCondensed";
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .titleSection .title {
    font-size: calc(100vw * 32 / 375);
    line-height: calc(100vw * 39 / 375);
  }
}

.row {
  display: flex;
}

@media screen and (max-width: 992px) {
  .row {
    display: block;
  }
}

.col {
  background: #dd0b1c;
  width: calc(100vw * 330 / 1366);
  height: calc(100vw * 230 / 1366);
  margin-right: calc(100vw * 55 / 1366);
  margin-bottom: calc(100vw * 30 / 1366);
  float: left;
  border-radius: 15px;
  background: radial-gradient(
    circle,
    #dd0b1c 67%,
    #990813 100%,
    rgba(0, 0, 0, 0.557861) 100%
  );
}

@media screen and (max-width: 992px) {
  .col {
    width: calc(100vw * 330 / 375);
    height: calc(100vw * 230 / 375);
    float: none;
    margin: 0 auto calc(100vw * 30 / 375);
  }
}

.colContent {
  padding: calc(100vw * 20 / 1366);
}

@media screen and (max-width: 992px) {
  .colContent {
    padding: calc(100vw * 20 / 375);
  }
}

.imgBox {
  width: calc(100vw * 200 / 1366);
  height: calc(100vw * 80 / 1366);
  margin: 0 auto;
}
@media screen and (max-width: 992px) {
  .imgBox {
    width: calc(100vw * 200 / 375);
    height: calc(100vw * 80 / 375);
  }
}

.img {
  width: inherit;
  height: 100%;
}

.cardText {
  font-family: "Bebas Neue";
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  padding-top: calc(100vw * 30 / 1366);
  font-size: calc(100vw * 27 / 1366);
  line-height: calc(100vw * 27 / 1366);
}

@media screen and (max-width: 992px) {
  .cardText {
    padding-top: calc(100vw * 30 / 375);
    font-size: calc(100vw * 27 / 375);
    line-height: calc(100vw * 27 / 375);
  }
}

.col:nth-child(3n) {
  margin-right: 0;
}
@media screen and (max-width: 992px) {
  .col:nth-child(3n) {
    margin: 0 auto calc(100vw * 30 / 375);
  }
}

.boxControls {
  text-align: center;
  margin-top: calc(100vw * 34 / 1366);
}
@media screen and (max-width: 992px) {
  .boxControls {
    margin-top: calc(100vw * 14 / 375);
  }
}
.boxControls .btn {
  display: inline-block;
  cursor: pointer;
  font-family: "Bebas Neue Book";
  border: none;
}
.boxControls .btn.btn-primary {
  background: #dd0b1c;
  border-radius: 50px;
  font-size: calc(100vw * 18 / 1366);
  line-height: calc(100vw * 22 / 1366);
  letter-spacing: 0.25em;
  color: #ffffff;
  padding: calc(100vw * 12 / 1366) calc(100vw * 39 / 1366)
    calc(100vw * 13 / 1366);
  transition: background 300ms ease-in-out;
  text-transform: uppercase;
  font-weight: 700;
}
.boxControls .btn.btn-primary:hover {
  background: rgba(211, 11, 28, 0.9);
}
@media screen and (max-width: 992px) {
  .boxControls .btn.btn-primary {
    font-size: calc(100vw * 24 / 375);
    line-height: calc(100vw * 26 / 375);
    padding: calc(100vw * 12 / 375) calc(100vw * 39 / 375)
      calc(100vw * 13 / 375);
  }
}
