.instructivo .page-title {
  height: calc(100vw * 230 / 1366);
}
@media screen and (max-width: 992px) {
  .instructivo .page-title {
    height: calc(100vw * 171 / 375);
    padding-top: calc(100vw * 180 / 375);
  }
}

.page-title h1 {
  font-size: calc(100vw * 51 / 1366);
  line-height: calc(100vw * 59 / 1366);
  color: #dd0b1c;
  font-family: "TCCC-UnityCondensed";
  font-weight: bold;
}
@media screen and (max-width: 992px) {
  .page-title h1 {
    font-size: calc(100vw * 32 / 375);
    line-height: calc(100vw * 39 / 375);
  }
}

.instructivo .page-title h1 {
  top: calc(100vw * 40 / 1366);
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  .instructivo .page-title h1 {
    top: calc(100vw * 40 / 375);
  }
}

.page-title .content-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.canalLogo {
  /* // TODO: Cambiar */
  width: 80px;
}

.page-title .content-box .text {
  width: calc(100vw * 158 / 1366);
  text-align: left;
  font-size: calc(100vw * 31 / 1366);
  line-height: calc(100vw * 28 / 1366);
  color: #dd0b1c;
  margin-left: calc(100vw * 20 / 1366);
  font-family: "Bebas Neue Book";
}
@media screen and (max-width: 992px) {
  .page-title .content-box .text {
    width: calc(100vw * 158 / 375);
    font-size: calc(100vw * 31 / 375);
    line-height: calc(100vw * 28 / 375);
    margin-left: calc(100vw * 20 / 375);
  }
}

.section-title {
  text-align: center;
  margin-top: calc(100vw * 33 / 1366);
  margin-bottom: calc(100vw * 44 / 1366);
}
@media screen and (max-width: 992px) {
  .section-title {
    margin-top: calc(100vw * 33 / 375);
    margin-bottom: calc(100vw * 44 / 375);
  }
}
.section-title h3 {
  font-size: calc(100vw * 20 / 1366);
  line-height: calc(100vw * 17 / 1366);
  letter-spacing: 0px;
  color: #0b4499;
  text-transform: capitalize;
  margin-bottom: calc(100vw * 14 / 1366);
}
@media screen and (max-width: 992px) {
  .section-title h3 {
    font-size: calc(100vw * 23 / 375);
    line-height: calc(100vw * 24 / 375);
    text-align: center;
    margin-bottom: calc(100vw * 14 / 375);
  }
}
.section-title h2 {
  font-size: calc(100vw * 34 / 1366);
  line-height: calc(100vw * 40 / 1366);
  font-weight: bold;
  color: #dd0b1c;
  opacity: 1;
  font-family: "TCCC-UnityCondensed";
}
@media screen and (max-width: 992px) {
  .section-title h2 {
    font-size: calc(100vw * 28 / 375);
    line-height: calc(100vw * 30 / 375);
    text-align: center;
    margin-bottom: calc(100vw * -5 / 375);
  }
}

.instructivo .instructions .content {
  width: calc(100vw * 490 / 1366);
  margin: 0 auto;
  text-transform: none;
}
@media screen and (max-width: 992px) {
  .instructivo .instructions .content {
    width: calc(100vw * 300 / 375);
  }
}

.instructivo .instructions .content ul li {
  margin-bottom: calc(100vw * 40 / 1366);
}

@media screen and (max-width: 992px) {
  .instructivo .instructions .content ul li {
    margin-bottom: calc(100vw * 40 / 375);
  }
}
.instructivo .instructions .content ul li .item {
  position: relative;
}
.instructivo .instructions .content ul li .item .number {
  background: #dd0b1c;
  color: #fff;
  border-radius: 100%;
  width: calc(100vw * 40 / 1366);
  height: calc(100vw * 40 / 1366);
  position: absolute;
  left: 0;
}
@media screen and (max-width: 992px) {
  .instructivo .instructions .content ul li .item .number {
    width: calc(100vw * 40 / 375);
    height: calc(100vw * 40 / 375);
  }
}
.instructivo .instructions .content ul li .item .number .num {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "TCCC-UnityCondensed";
  font-weight: bold;
  font-size: calc(100vw * 24 / 1366);
}
@media screen and (max-width: 992px) {
  .instructivo .instructions .content ul li .item .number .num {
    font-size: calc(100vw * 24 / 375);
  }
}
.instructivo .instructions .content ul li .item .text-box {
  margin-left: calc(100vw * 60 / 1366);
}
@media screen and (max-width: 992px) {
  .instructivo .instructions .content ul li .item .text-box {
    margin-left: calc(100vw * 60 / 375);
  }
}
.instructivo .instructions .content ul li .item .text-box p {
  font-family: "TCCC-UnityCondensed";
  font-size: calc(100vw * 30 / 1366);
}
@media screen and (max-width: 992px) {
  .instructivo .instructions .content ul li .item .text-box p {
    font-size: calc(100vw * 30 / 375);
  }
}
.instructivo .instructions .content ul li .item .text-box p strong {
  font-weight: bold;
  color: #dd0b1c;
}
.instructivo .instructions .content ul li .item .text-box ul {
  margin-top: calc(100vw * 10 / 1366);
}
@media screen and (max-width: 992px) {
  .instructivo .instructions .content ul li .item .text-box ul {
    margin-top: calc(100vw * 10 / 375);
    display: flex;
    flex-direction: column;
  }
}
.instructivo .instructions .content ul li .item .text-box ul li {
  display: inline-block;
  width: calc(100vw * 120 / 1366);
  margin-bottom: 0;
  margin-right: calc(100vw * 20 / 1366);
}
@media screen and (max-width: 992px) {
  .instructivo .instructions .content ul li .item .text-box ul li {
    margin-right: 0;
    width: calc(100vw * 120 / 375);
    margin-bottom: calc(100vw * 10 / 375);
  }
  .instructivo .instructions .content ul li .item .text-box ul li:first-child {
    margin-top: calc(100vw * 10 / 375);
  }
  .instructivo .instructions .content ul li .item .text-box ul li:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 992px) {
  .instructivo .wait .divider {
    width: calc(100vw * 300 / 375);
    margin: 0 auto;
  }
}
.instructivo .wait .content-box {
  width: calc(100vw * 360 / 1366);
  clear: both;
  overflow: hidden;
  margin: calc(100vw * 40 / 1366) auto;
}
@media screen and (max-width: 992px) {
  .instructivo .wait .content-box {
    width: calc(100vw * 300 / 375);
    margin: calc(100vw * 40 / 375) auto 0;
  }
}
.instructivo .wait .content-box .text {
  float: left;
  width: calc(100vw * 200 / 1366);
}
@media screen and (max-width: 992px) {
  .instructivo .wait .content-box .text {
    width: 100%;
    float: none;
  }
}
.instructivo .wait .content-box .text p {
  text-transform: uppercase;
  font-family: "TCCC-UnityCondensed";
  font-weight: bold;
  font-size: calc(100vw * 28 / 1366);
}
@media screen and (max-width: 992px) {
  .instructivo .wait .content-box .text p {
    font-size: calc(100vw * 28 / 375);
    text-align: center;
  }
}
.instructivo .wait .content-box .logo {
  float: right;
  width: calc(100vw * 150 / 1366);
}
@media screen and (max-width: 992px) {
  .instructivo .wait .content-box .logo {
    width: calc(100vw * 150 / 375);
    float: none;
    margin: 0 auto;
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .instructivo footer {
    border: none;
    margin-top: 0;
  }
}
